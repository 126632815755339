body {
  font-family: Tahoma, sans-serif;
}

.header {
  color: #fff;
  background-color: #388087;
}

.header a:hover {
  color: #c2edce;
  fill: #c2edce;
}

.header a {
  color: #fff;
}

.header svg {
  fill: #f6f6f2;
}

.content {
  background-color: #badfe7;
}

footer {
  color: #f6f6f2;
  background-color: #6fb3b8;
}

footer a {
  color: #c2edce;
}

.hamburger {
  background-color: #badfe7;
}

.menu-expandable {
  background-color: #f6f6f2;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 1.5;
}

h2 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.5;
}

h3 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.5;
}

h4 {
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.5;
}

h5 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
}

h6 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.\!absolute {
  position: absolute !important;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.\!top-0 {
  top: 0 !important;
}

.\!left-0 {
  left: 0 !important;
}

.left-full {
  left: 100%;
}

.top-\[50\%\] {
  top: 50%;
}

.left-\[50\%\] {
  left: 50%;
}

.top-1\/2 {
  top: 50%;
}

.right-3 {
  right: .75rem;
}

.right-12 {
  right: 3rem;
}

.top-2 {
  top: .5rem;
}

.left-2 {
  left: .5rem;
}

.left-44 {
  left: 11rem;
}

.bottom-20 {
  bottom: 5rem;
}

.right-20 {
  right: 5rem;
}

.top-full {
  top: 100%;
}

.top-auto {
  top: auto;
}

.left-auto {
  left: auto;
}

.bottom-\[-0\.75rem\] {
  bottom: -.75rem;
}

.left-2\/4 {
  left: 50%;
}

.z-10 {
  z-index: 10;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[2\] {
  z-index: 2;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.z-0 {
  z-index: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.float-left {
  float: left;
}

.m-0 {
  margin: 0;
}

.m-2 {
  margin: .5rem;
}

.m-auto {
  margin: auto;
}

.\!m-0 {
  margin: 0 !important;
}

.m-\[0\.143rem\] {
  margin: .143rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-0\.5 {
  margin-top: .125rem;
  margin-bottom: .125rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mr-0 {
  margin-right: 0;
}

.mt-3 {
  margin-top: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.mt-1 {
  margin-top: .25rem;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-\[-0\.5715rem\] {
  margin-top: -.5715rem;
}

.ml-\[-0\.5715rem\] {
  margin-left: -.5715rem;
}

.mb-\[-0\.5715rem\] {
  margin-bottom: -.5715rem;
}

.ml-\[-0\.4715rem\] {
  margin-left: -.4715rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.mt-0 {
  margin-top: 0;
}

.-mr-7 {
  margin-right: -1.75rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.mr-4 {
  margin-right: 1rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.ml-\[-0\.75rem\] {
  margin-left: -.75rem;
}

.\!mb-2 {
  margin-bottom: .5rem !important;
}

.mr-1 {
  margin-right: .25rem;
}

.mt-\[-0\.5rem\] {
  margin-top: -.5rem;
}

.ml-0 {
  margin-left: 0;
}

.-ml-3 {
  margin-left: -.75rem;
}

.mr-0\.5 {
  margin-right: .125rem;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.\!inline {
  display: inline !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-screen {
  height: 100vh;
}

.h-4 {
  height: 1rem;
}

.h-48 {
  height: 12rem;
}

.h-2 {
  height: .5rem;
}

.h-6 {
  height: 1.5rem;
}

.h-0 {
  height: 0;
}

.h-8 {
  height: 2rem;
}

.h-full {
  height: 100%;
}

.h-\[calc\(100\%\+18px\)\] {
  height: calc(100% + 18px);
}

.h-\[9px\] {
  height: 9px;
}

.h-7 {
  height: 1.75rem;
}

.\!h-screen {
  height: 100vh !important;
}

.h-40 {
  height: 10rem;
}

.\!h-16 {
  height: 4rem !important;
}

.h-12 {
  height: 3rem;
}

.h-5 {
  height: 1.25rem;
}

.h-1 {
  height: .25rem;
}

.h-56 {
  height: 14rem;
}

.h-3 {
  height: .75rem;
}

.h-10 {
  height: 2.5rem;
}

.h-44 {
  height: 11rem;
}

.h-\[1\.5rem\] {
  height: 1.5rem;
}

.h-\[2\.25rem\] {
  height: 2.25rem;
}

.h-\[3rem\] {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-9 {
  height: 2.25rem;
}

.h-28 {
  height: 7rem;
}

.h-72 {
  height: 18rem;
}

.h-\[2rem\] {
  height: 2rem;
}

.h-\[2px\] {
  height: 2px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[1\.143rem\] {
  height: 1.143rem;
}

.max-h-32 {
  max-height: 8rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-0 {
  max-height: 0;
}

.max-h-40 {
  max-height: 10rem;
}

.\!max-h-full {
  max-height: 100% !important;
}

.max-h-\[200px\] {
  max-height: 200px;
}

.max-h-\[24rem\] {
  max-height: 24rem;
}

.min-h-full {
  min-height: 100%;
}

.min-h-\[70px\] {
  min-height: 70px;
}

.min-h-0 {
  min-height: 0;
}

.min-h-\[12rem\] {
  min-height: 12rem;
}

.w-4 {
  width: 1rem;
}

.w-48 {
  width: 12rem;
}

.w-2 {
  width: .5rem;
}

.w-0 {
  width: 0;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.w-\[calc\(100\%\+18px\)\] {
  width: calc(100% + 18px);
}

.w-\[9px\] {
  width: 9px;
}

.w-12 {
  width: 3rem;
}

.w-7 {
  width: 1.75rem;
}

.w-\[50vw\] {
  width: 50vw;
}

.\!w-screen {
  width: 100vw !important;
}

.w-80 {
  width: 20rem;
}

.w-6 {
  width: 1.5rem;
}

.w-96 {
  width: 24rem;
}

.w-16 {
  width: 4rem;
}

.w-\[3rem\] {
  width: 3rem;
}

.w-1 {
  width: .25rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-36 {
  width: 9rem;
}

.w-3 {
  width: .75rem;
}

.w-10 {
  width: 2.5rem;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/2 {
  width: 50%;
}

.w-52 {
  width: 13rem;
}

.w-40 {
  width: 10rem;
}

.w-28 {
  width: 7rem;
}

.w-9 {
  width: 2.25rem;
}

.w-auto {
  width: auto;
}

.w-\[2rem\] {
  width: 2rem;
}

.w-\[12\.5rem\] {
  width: 12.5rem;
}

.w-20 {
  width: 5rem;
}

.w-\[2px\] {
  width: 2px;
}

.w-px {
  width: 1px;
}

.w-\[1\%\] {
  width: 1%;
}

.w-\[1\.143rem\] {
  width: 1.143rem;
}

.min-w-\[0\.5rem\] {
  min-width: .5rem;
}

.min-w-\[1\.5rem\] {
  min-width: 1.5rem;
}

.min-w-full {
  min-width: 100%;
}

.min-w-\[350px\] {
  min-width: 350px;
}

.min-w-\[2\.25rem\] {
  min-width: 2.25rem;
}

.min-w-\[3rem\] {
  min-width: 3rem;
}

.min-w-\[1\.143rem\] {
  min-width: 1.143rem;
}

.min-w-\[12\.5rem\] {
  min-width: 12.5rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-\[30rem\] {
  max-width: 30rem;
}

.max-w-\[3rem\] {
  max-width: 3rem;
}

.flex-initial {
  flex: 0 auto;
}

.flex-none {
  flex: none;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.basis-2\/4 {
  flex-basis: 50%;
}

.table-fixed {
  table-layout: fixed;
}

.border-collapse {
  border-collapse: collapse;
}

.border-separate {
  border-collapse: separate;
}

.border-spacing-0 {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.origin-top-right {
  transform-origin: 100% 0;
}

.origin-center {
  transform-origin: center;
}

.origin-bottom {
  transform-origin: bottom;
}

.origin-top {
  transform-origin: top;
}

.origin-left {
  transform-origin: 0;
}

.origin-right {
  transform-origin: 100%;
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.translate-y-2\/4 {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.transform-none {
  transform: none;
}

.\!transform-none {
  transform: none !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-row-resize {
  cursor: row-resize;
}

.cursor-grab {
  cursor: grab;
}

.cursor-auto {
  cursor: auto;
}

.cursor-text {
  cursor: text;
}

.cursor-move {
  cursor: move;
}

.touch-none {
  touch-action: none;
}

.select-none {
  -ms-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  -ms-appearance: none;
  appearance: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  align-content: center;
  justify-content: center;
}

.items-center {
  align-items: center;
}

.\!items-center {
  align-items: center !important;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.\!justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-2 {
  gap: .5rem;
}

.self-center {
  align-self: center;
}

.self-baseline {
  align-self: baseline;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overscroll-auto {
  overscroll-behavior: auto;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-none {
  border-radius: 0;
}

.rounded-lg {
  border-radius: .5rem;
}

.\!rounded-full {
  border-radius: 9999px !important;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[16px\] {
  border-radius: 16px;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-b-lg {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-b-md {
  border-bottom-left-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.rounded-br-md {
  border-bottom-right-radius: .375rem;
}

.rounded-bl-md {
  border-bottom-left-radius: .375rem;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tr-none {
  border-top-right-radius: 0;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-br-none {
  border-bottom-right-radius: 0;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded-tr-md {
  border-top-right-radius: .375rem;
}

.rounded-tl-md {
  border-top-left-radius: .375rem;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-y-\[0\.25rem\] {
  border-top-width: .25rem;
  border-bottom-width: .25rem;
}

.border-x-\[0\.25rem\] {
  border-left-width: .25rem;
  border-right-width: .25rem;
}

.border-x-0 {
  border-left-width: 0;
  border-right-width: 0;
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-r-\[0\.25rem\] {
  border-right-width: .25rem;
}

.border-l-0 {
  border-left-width: 0;
}

.border-l-\[0\.25rem\] {
  border-left-width: .25rem;
}

.border-r-0 {
  border-right-width: 0;
}

.border-t-\[0\.25rem\] {
  border-top-width: .25rem;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-b-\[0\.25rem\] {
  border-bottom-width: .25rem;
}

.border-t-0 {
  border-top-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity) );
}

.border-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity) );
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity) );
}

.border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity) );
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity) );
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity) );
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity) );
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity) );
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity) );
}

.border-white\/20 {
  border-color: rgba(255, 255, 255, .2);
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity) );
}

.border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgb(239 246 255 / var(--tw-border-opacity) );
}

.border-r-gray-600 {
  --tw-border-opacity: 1;
  border-right-color: rgb(75 85 99 / var(--tw-border-opacity) );
}

.border-l-gray-600 {
  --tw-border-opacity: 1;
  border-left-color: rgb(75 85 99 / var(--tw-border-opacity) );
}

.border-t-gray-600 {
  --tw-border-opacity: 1;
  border-top-color: rgb(75 85 99 / var(--tw-border-opacity) );
}

.border-b-gray-600 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(75 85 99 / var(--tw-border-opacity) );
}

.bg-white\/50 {
  background-color: rgba(255, 255, 255, .5);
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity) );
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity) );
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity) );
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity) );
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity) );
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity) );
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity) );
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity) );
}

.bg-black\/40 {
  background-color: rgba(0, 0, 0, .4);
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity) );
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity) );
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity) );
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity) );
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity) );
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity) );
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity) );
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity) );
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.\!bg-blue-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity) ) !important;
}

.\!bg-gray-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity) ) !important;
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity) );
}

.bg-white\/10 {
  background-color: rgba(255, 255, 255, .1);
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.bg-black\/90 {
  background-color: rgba(0, 0, 0, .9);
}

.bg-black\/50 {
  background-color: rgba(0, 0, 0, .5);
}

.bg-inherit {
  background-color: inherit;
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity) );
}

.bg-blue-500\/20 {
  background-color: rgba(59, 130, 246, .2);
}

.bg-blue-500\/30 {
  background-color: rgba(59, 130, 246, .3);
}

.bg-blue-300\/40 {
  background-color: rgba(147, 197, 253, .4);
}

.bg-gray-100\/40 {
  background-color: rgba(243, 244, 246, .4);
}

.bg-blue-100\/50 {
  background-color: rgba(219, 234, 254, .5);
}

.bg-opacity-90 {
  --tw-bg-opacity: .9;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-gray-200 {
  stroke: #e5e7eb;
}

.stroke-blue-500 {
  stroke: #3b82f6;
}

.p-4 {
  padding: 1rem;
}

.p-1 {
  padding: .25rem;
}

.p-0 {
  padding: 0;
}

.p-3 {
  padding: .75rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.\!p-0 {
  padding: 0 !important;
}

.p-2 {
  padding: .5rem;
}

.\!p-3 {
  padding: .75rem !important;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.\!py-1\.5 {
  padding-top: .375rem !important;
  padding-bottom: .375rem !important;
}

.\!py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-0 {
  padding-right: 0;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pl-2\.5 {
  padding-left: .625rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-\[18px\] {
  padding-right: 18px;
}

.pb-\[18px\] {
  padding-bottom: 18px;
}

.pl-0 {
  padding-left: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pr-\[2\.5rem\] {
  padding-right: 2.5rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pl-0\.5 {
  padding-left: .125rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-none {
  line-height: 1;
}

.leading-8 {
  line-height: 2rem;
}

.leading-\[1\.5rem\] {
  line-height: 1.5rem;
}

.leading-\[2\.25rem\] {
  line-height: 2.25rem;
}

.leading-\[3rem\] {
  line-height: 3rem;
}

.leading-\[1\.143rem\] {
  line-height: 1.143rem;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity) );
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity) );
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity) );
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity) );
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity) );
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity) );
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity) );
}

.text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity) );
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity) );
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity) );
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity) );
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity) );
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity) );
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity) );
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity) );
}

.text-inherit {
  color: inherit;
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity) );
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-60 {
  opacity: .6;
}

.opacity-90 {
  opacity: .9;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-\[0_0_0_0\.2rem_rgba\(191\,219\,254\,1\)\] {
  --tw-shadow: 0 0 0 .2rem #bfdbfe;
  --tw-shadow-colored: 0 0 0 .2rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-\[0_2px_12px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 0 2px 12px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 2px 12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.outline {
  outline-style: solid;
}

.outline-offset-0 {
  outline-offset: 0px;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-shadow {
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-none {
  transition-property: none;
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\!transition-none {
  transition-property: none !important;
}

.delay-200 {
  transition-delay: .2s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-\[250ms\] {
  transition-duration: .25s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-1000 {
  transition-duration: 1s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:top-1\/2:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:left-1\/2:before {
  content: var(--tw-content);
  left: 50%;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:-top-3:before {
  content: var(--tw-content);
  top: -.75rem;
}

.before\:left-1:before {
  content: var(--tw-content);
  left: .25rem;
}

.before\:bottom-0:before {
  content: var(--tw-content);
  bottom: 0;
}

.before\:top-1\/4:before {
  content: var(--tw-content);
  top: 25%;
}

.before\:ml-6:before {
  content: var(--tw-content);
  margin-left: 1.5rem;
}

.before\:-mt-2\.5:before {
  content: var(--tw-content);
  margin-top: -.625rem;
}

.before\:-mt-2:before {
  content: var(--tw-content);
  margin-top: -.5rem;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:h-0:before {
  content: var(--tw-content);
  height: 0;
}

.before\:h-5:before {
  content: var(--tw-content);
  height: 1.25rem;
}

.before\:min-h-full:before {
  content: var(--tw-content);
  min-height: 100%;
}

.before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}

.before\:w-0:before {
  content: var(--tw-content);
  width: 0;
}

.before\:w-5:before {
  content: var(--tw-content);
  width: 1.25rem;
}

.before\:-translate-x-1\/2:before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.before\:translate-x-5:before {
  content: var(--tw-content);
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.before\:-translate-y-1\/2:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.before\:transform:before {
  content: var(--tw-content);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:border-x-\[0\.75rem\]:before {
  content: var(--tw-content);
  border-left-width: .75rem;
  border-right-width: .75rem;
}

.before\:border-t:before {
  content: var(--tw-content);
  border-top-width: 1px;
}

.before\:border-l:before {
  content: var(--tw-content);
  border-left-width: 1px;
}

.before\:border-b-\[0\.75rem\]:before {
  content: var(--tw-content);
  border-bottom-width: .75rem;
}

.before\:border-t-0:before {
  content: var(--tw-content);
  border-top-width: 0;
}

.before\:border-solid:before {
  content: var(--tw-content);
  border-style: solid;
}

.before\:border-dashed:before {
  content: var(--tw-content);
  border-style: dashed;
}

.before\:border-dotted:before {
  content: var(--tw-content);
  border-style: dotted;
}

.before\:border-gray-300:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.before\:border-transparent:before {
  content: var(--tw-content);
  border-color: rgba(0, 0, 0, 0);
}

.before\:border-b-white:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity) );
}

.before\:bg-white:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.before\:bg-inherit:before {
  content: var(--tw-content);
  background-color: inherit;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:top-0:after {
  content: var(--tw-content);
  top: 0;
}

.after\:left-0:after {
  content: var(--tw-content);
  left: 0;
}

.after\:bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.after\:right-0:after {
  content: var(--tw-content);
  right: 0;
}

.after\:left-full:after {
  content: var(--tw-content);
  left: 100%;
}

.after\:z-10:after {
  content: var(--tw-content);
  z-index: 10;
}

.after\:h-full:after {
  content: var(--tw-content);
  height: 100%;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:translate-x-full:after {
  content: var(--tw-content);
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.after\:transform:after {
  content: var(--tw-content);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.after\:bg-inherit:after {
  content: var(--tw-content);
  background-color: inherit;
}

.after\:bg-blue-400:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity) );
}

.after\:bg-gradient-to-r:after {
  content: var(--tw-content);
  background-image: linear-gradient(to right, var(--tw-gradient-stops) );
}

.after\:from-transparent:after {
  content: var(--tw-content);
  --tw-gradient-from: transparent;
  --tw-gradient-to: rgba(0, 0, 0, 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.after\:via-white:after {
  content: var(--tw-content);
  --tw-gradient-to: rgba(255, 255, 255, 0);
  --tw-gradient-stops: var(--tw-gradient-from), #fff, var(--tw-gradient-to);
}

.after\:to-transparent:after {
  content: var(--tw-content);
  --tw-gradient-to: transparent;
}

.after\:delay-1000:after {
  content: var(--tw-content);
  transition-delay: 1s;
}

.first\:mb-2:first-child {
  margin-bottom: .5rem;
}

.first\:mt-2:first-child {
  margin-top: .5rem;
}

.first\:mr-2:first-child {
  margin-right: .5rem;
}

.first\:ml-2:first-child {
  margin-left: .5rem;
}

.first\:rounded-l-md:first-child {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.first\:rounded-tr-none:first-child {
  border-top-right-radius: 0;
}

.first\:rounded-br-none:first-child {
  border-bottom-right-radius: 0;
}

.first\:border-l-0:first-child {
  border-left-width: 0;
}

.first\:pl-0:first-child {
  padding-left: 0;
}

.last\:mr-0:last-child {
  margin-right: 0;
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.last\:rounded-r-md:last-child {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.last\:rounded-br-lg:last-child {
  border-bottom-right-radius: .5rem;
}

.last\:rounded-bl-lg:last-child {
  border-bottom-left-radius: .5rem;
}

.last\:rounded-tl-none:last-child {
  border-top-left-radius: 0;
}

.last\:rounded-bl-none:last-child {
  border-bottom-left-radius: 0;
}

.last\:border-r:last-child {
  border-right-width: 1px;
}

.hover\:mx-6:hover {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.hover\:my-6:hover {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.hover\:scale-150:hover {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border:hover {
  border-width: 1px;
}

.hover\:border-transparent:hover {
  border-color: rgba(0, 0, 0, 0);
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity) );
}

.hover\:border-blue-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity) );
}

.hover\:border-gray-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity) );
}

.hover\:border-green-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity) );
}

.hover\:border-orange-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(234 88 12 / var(--tw-border-opacity) );
}

.hover\:border-purple-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity) );
}

.hover\:border-red-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity) );
}

.hover\:border-blue-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity) );
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity) );
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.hover\:bg-white\/30:hover {
  background-color: rgba(255, 255, 255, .3);
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity) );
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity) );
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity) );
}

.hover\:bg-orange-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity) );
}

.hover\:bg-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity) );
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity) );
}

.hover\:bg-blue-300\/20:hover {
  background-color: rgba(147, 197, 253, .2);
}

.hover\:bg-gray-300\/20:hover {
  background-color: rgba(209, 213, 219, .2);
}

.hover\:bg-green-300\/20:hover {
  background-color: rgba(134, 239, 172, .2);
}

.hover\:bg-orange-300\/20:hover {
  background-color: rgba(253, 186, 116, .2);
}

.hover\:bg-purple-300\/20:hover {
  background-color: rgba(216, 180, 254, .2);
}

.hover\:bg-red-300\/20:hover {
  background-color: rgba(252, 165, 165, .2);
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity) );
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity) );
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity) );
}

.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity) );
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.hover\:bg-white\/10:hover {
  background-color: rgba(255, 255, 255, .1);
}

.hover\:bg-opacity-50:hover {
  --tw-bg-opacity: .5;
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity) );
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity) );
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity) );
}

.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity) );
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity) );
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.hover\:text-slate-700:hover {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity) );
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:transition-opacity:hover {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hover\:duration-300:hover {
  transition-duration: .3s;
}

.focus\:border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity) );
}

.focus\:shadow-\[0_0_0_0\.2rem_rgba\(191\,219\,254\,1\)\]:focus {
  --tw-shadow: 0 0 0 .2rem #bfdbfe;
  --tw-shadow-colored: 0 0 0 .2rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[inset_0_0_0_0\.2rem_rgba\(191\,219\,254\,1\)\]:focus, .focus\:focus\:shadow-\[inset_0_0_0_0\.2rem_rgba\(191\,219\,254\,1\)\]:focus:focus {
  --tw-shadow: inset 0 0 0 .2rem #bfdbfe;
  --tw-shadow-colored: inset 0 0 0 .2rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[0_0_0_2px_rgba\(255\,255\,255\,1\)\,0_0_0_4px_rgba\(157\,193\,251\,1\)\,0_1px_2px_0_rgba\(0\,0\,0\,1\)\]:focus {
  --tw-shadow: 0 0 0 2px #fff, 0 0 0 4px #9dc1fb, 0 1px 2px 0 #000;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[0_0_0_2px_rgba\(255\,255\,255\,1\)\,0_0_0_4px_rgba\(176\,185\,198\,1\)\,0_1px_2px_0_rgba\(0\,0\,0\,1\)\]:focus {
  --tw-shadow: 0 0 0 2px #fff, 0 0 0 4px #b0b9c6, 0 1px 2px 0 #000;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[0_0_0_2px_rgba\(255\,255\,255\,1\)\,0_0_0_4px_rgba\(136\,234\,172\,1\)\,0_1px_2px_0_rgba\(0\,0\,0\,1\)\]:focus {
  --tw-shadow: 0 0 0 2px #fff, 0 0 0 4px #88eaac, 0 1px 2px 0 #000;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[0_0_0_2px_rgba\(255\,255\,255\,1\)\,0_0_0_4px_rgba\(250\,207\,133\,1\)\,0_1px_2px_0_rgba\(0\,0\,0\,1\)\]:focus {
  --tw-shadow: 0 0 0 2px #fff, 0 0 0 4px #facf85, 0 1px 2px 0 #000;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[0_0_0_2px_rgba\(255\,255\,255\,1\)\,0_0_0_4px_rgba\(212\,170\,251\,1\)\,0_1px_2px_0_rgba\(0\,0\,0\,1\)\]:focus {
  --tw-shadow: 0 0 0 2px #fff, 0 0 0 4px #d4aafb, 0 1px 2px 0 #000;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-\[0_0_0_2px_rgba\(255\,255\,255\,1\)\,0_0_0_4px_rgba\(247\,162\,162\,1\)\,0_1px_2px_0_rgba\(0\,0\,0\,1\)\]:focus {
  --tw-shadow: 0 0 0 2px #fff, 0 0 0 4px #f7a2a2, 0 1px 2px 0 #000;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.focus\:outline:focus {
  outline-style: solid;
}

.focus\:outline-\[0\.15rem\]:focus {
  outline-width: .15rem;
}

.focus\:outline-0:focus {
  outline-width: 0;
}

.focus\:outline-offset-0:focus {
  outline-offset: 0px;
}

.focus\:outline-offset-\[-0\.15rem\]:focus {
  outline-offset: -.15rem;
}

.focus\:outline-blue-200:focus {
  outline-color: #bfdbfe;
}

.dark .dark\:border {
  border-width: 1px;
}

.dark .dark\:border-blue-900\/40 {
  border-color: rgba(30, 58, 138, .4);
}

.dark .dark\:border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity) );
}

.dark .dark\:border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity) );
}

.dark .dark\:border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity) );
}

.dark .dark\:border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity) );
}

.dark .dark\:border-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 146 60 / var(--tw-border-opacity) );
}

.dark .dark\:border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgb(192 132 252 / var(--tw-border-opacity) );
}

.dark .dark\:border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity) );
}

.dark .dark\:border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.dark .dark\:border-sky-300 {
  --tw-border-opacity: 1;
  border-color: rgb(125 211 252 / var(--tw-border-opacity) );
}

.dark .dark\:bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity) );
}

.dark .dark\:bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.dark .dark\:bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity) );
}

.dark .dark\:bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity) );
}

.dark .dark\:bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity) );
}

.dark .dark\:bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity) );
}

.dark .dark\:bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity) );
}

.dark .dark\:bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity) );
}

.dark .dark\:bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity) );
}

.dark .dark\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity) );
}

.dark .dark\:bg-gray-800\/90 {
  background-color: rgba(31, 41, 55, .9);
}

.dark .dark\:bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity) );
}

.dark .dark\:bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.dark .dark\:bg-blue-900\/40 {
  background-color: rgba(30, 58, 138, .4);
}

.dark .dark\:bg-sky-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(125 211 252 / var(--tw-bg-opacity) );
}

.dark .dark\:bg-blue-300\/20 {
  background-color: rgba(147, 197, 253, .2);
}

.dark .dark\:bg-blue-300\/30 {
  background-color: rgba(147, 197, 253, .3);
}

.dark .dark\:bg-blue-500\/40 {
  background-color: rgba(59, 130, 246, .4);
}

.dark .dark\:stroke-gray-700 {
  stroke: #374151;
}

.dark .dark\:text-white\/80 {
  color: rgba(255, 255, 255, .8);
}

.dark .dark\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.dark .dark\:text-white\/60 {
  color: rgba(255, 255, 255, .6);
}

.dark .dark\:text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity) );
}

.dark .dark\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.dark .dark\:text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity) );
}

.dark .dark\:text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity) );
}

.dark .dark\:text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity) );
}

.dark .dark\:text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity) );
}

.dark .dark\:text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity) );
}

.dark .dark\:text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity) );
}

.dark .dark\:text-white\/70 {
  color: rgba(255, 255, 255, .7);
}

.dark .dark\:shadow-\[0_0_0_0\.2rem_rgba\(147\,197\,253\,0\.5\)\] {
  --tw-shadow: 0 0 0 .2rem rgba(147, 197, 253, .5);
  --tw-shadow-colored: 0 0 0 .2rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.dark .before\:dark\:border-blue-900\/40:before {
  content: var(--tw-content);
  border-color: rgba(30, 58, 138, .4);
}

.dark .dark\:before\:border-b-gray-900:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgb(17 24 39 / var(--tw-border-opacity) );
}

.dark .before\:dark\:bg-gray-900:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity) );
}

.dark .dark\:hover\:border-transparent:hover {
  border-color: rgba(0, 0, 0, 0);
}

.dark .dark\:hover\:border-blue-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity) );
}

.dark .dark\:hover\:border-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity) );
}

.dark .dark\:hover\:border-green-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity) );
}

.dark .dark\:hover\:border-orange-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity) );
}

.dark .dark\:hover\:border-purple-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity) );
}

.dark .dark\:hover\:border-red-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity) );
}

.dark .dark\:hover\:border-blue-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity) );
}

.dark .dark\:hover\:border-blue-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity) );
}

.dark .dark\:hover\:bg-gray-800\/80:hover {
  background-color: rgba(31, 41, 55, .8);
}

.dark .dark\:hover\:bg-gray-800\/60:hover {
  background-color: rgba(31, 41, 55, .6);
}

.dark .dark\:hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity) );
}

.dark .dark\:hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity) );
}

.dark .dark\:hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity) );
}

.dark .dark\:hover\:bg-orange-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity) );
}

.dark .dark\:hover\:bg-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity) );
}

.dark .dark\:hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity) );
}

.dark .hover\:dark\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity) );
}

.dark .dark\:hover\:bg-gray-800\/70:hover {
  background-color: rgba(31, 41, 55, .7);
}

.dark .dark\:hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity) );
}

.dark .dark\:hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity) );
}

.dark .dark\:hover\:text-white\/80:hover {
  color: rgba(255, 255, 255, .8);
}

.dark .dark\:hover\:text-white\/70:hover {
  color: rgba(255, 255, 255, .7);
}

.dark .dark\:focus\:shadow-\[inset_0_0_0_0\.2rem_rgba\(147\,197\,253\,0\.5\)\]:focus {
  --tw-shadow: inset 0 0 0 .2rem rgba(147, 197, 253, .5);
  --tw-shadow-colored: inset 0 0 0 .2rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.dark .dark\:focus\:shadow-\[0_0_0_2px_rgba\(28\,33\,39\,1\)\,0_0_0_4px_rgba\(147\,197\,253\,0\.7\)\,0_1px_2px_0_rgba\(0\,0\,0\,0\)\]:focus {
  --tw-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(147, 197, 253, .7), 0 1px 2px 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.dark .dark\:focus\:shadow-\[0_0_0_2px_rgba\(28\,33\,39\,1\)\,0_0_0_4px_rgba\(203\,213\,225\,0\.7\)\,0_1px_2px_0_rgba\(0\,0\,0\,0\)\]:focus {
  --tw-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(203, 213, 225, .7), 0 1px 2px 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.dark .dark\:focus\:shadow-\[0_0_0_2px_rgba\(28\,33\,39\,1\)\,0_0_0_4px_rgba\(134\,239\,172\,0\.7\)\,0_1px_2px_0_rgba\(0\,0\,0\,0\)\]:focus {
  --tw-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(134, 239, 172, .7), 0 1px 2px 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.dark .dark\:focus\:shadow-\[0_0_0_2px_rgba\(28\,33\,39\,1\)\,0_0_0_4px_rgba\(252\,211\,77\,0\.7\)\,0_1px_2px_0_rgba\(0\,0\,0\,0\)\]:focus {
  --tw-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(252, 211, 77, .7), 0 1px 2px 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.dark .dark\:focus\:shadow-\[0_0_0_2px_rgba\(28\,33\,39\,1\)\,0_0_0_4px_rgba\(216\,180\,254\,0\.7\)\,0_1px_2px_0_rgba\(0\,0\,0\,0\)\]:focus {
  --tw-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(216, 180, 254, .7), 0 1px 2px 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.dark .dark\:focus\:shadow-\[0_0_0_2px_rgba\(28\,33\,39\,1\)\,0_0_0_4px_rgba\(252\,165\,165\,0\.7\)\,0_1px_2px_0_rgba\(0\,0\,0\,0\)\]:focus {
  --tw-shadow: 0 0 0 2px #1c2127, 0 0 0 4px rgba(252, 165, 165, .7), 0 1px 2px 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color), 0 0 0 4px var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.dark .dark\:focus\:shadow-\[0_0_0_0\.2rem_rgba\(147\,197\,253\,0\.5\)\]:focus {
  --tw-shadow: 0 0 0 .2rem rgba(147, 197, 253, .5);
  --tw-shadow-colored: 0 0 0 .2rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.dark .dark\:focus\:outline:focus {
  outline-style: solid;
}

.dark .dark\:focus\:outline-\[0\.15rem\]:focus {
  outline-width: .15rem;
}

.dark .dark\:focus\:outline-offset-\[-0\.15rem\]:focus {
  outline-offset: -.15rem;
}

.dark .dark\:focus\:outline-blue-300:focus {
  outline-color: #93c5fd;
}

@media (min-width: 640px) {
  .sm\:absolute {
    position: absolute;
  }

  .sm\:relative {
    position: relative;
  }

  .sm\:top-auto {
    top: auto;
  }

  .sm\:left-auto {
    left: auto;
  }

  .sm\:left-full {
    left: 100%;
  }

  .sm\:top-0 {
    top: 0;
  }

  .sm\:ml-2 {
    margin-left: .5rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-48 {
    width: 12rem;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:bg-transparent {
    background-color: rgba(0, 0, 0, 0);
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:pl-5 {
    padding-left: 1.25rem;
  }

  .sm\:shadow-none {
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }

  .sm\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }
}

@media (min-width: 768px) {
  .md\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-56 {
    width: 14rem;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:w-\[25rem\] {
    width: 25rem;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:grow {
    flex-grow: 1;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:flex {
    display: flex;
  }
}

@media (min-width: 1280px) {
  .xl\:pl-32 {
    padding-left: 8rem;
  }
}

@media (min-width: 1075px) {
  .smd\:block {
    display: block;
  }

  .smd\:hidden {
    display: none;
  }

  .smd\:flex-1 {
    flex: 1;
  }

  .smd\:grow {
    flex-grow: 1;
  }

  .smd\:text-center {
    text-align: center;
  }
}

.\[\&\:\:-webkit-scrollbar\]\:hidden::-webkit-scrollbar {
  display: none;
}

/*# sourceMappingURL=index.5441760d.css.map */
