@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-6xl font-semibold leading-normal;
    }
    h2 {
        @apply text-5xl font-semibold leading-normal;
    }
    h3 {
        @apply text-4xl font-semibold leading-normal;
    }
    h4 {
        @apply text-3xl font-semibold leading-normal;
    }
    h5 {
        @apply text-2xl font-semibold leading-normal;
    }
    h6 {
        @apply text-xl font-semibold leading-normal;
    }
}