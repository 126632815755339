body {
    font-family: "Tahoma", sans-serif;
}

.header {
    background-color: #388087;
    color: #FFF;
}

.header a:hover {
    color: #c2edce;
    fill: #c2edce;
}

.header a {
    color: #fff;
}

.header svg {
    fill: #f6f6f2;
}

.content {
    background-color: #badfe7;
}

footer {
    background-color: #6fb3b8;
    color: #f6f6f2;
}

footer a {
    color: #c2edce;
}

.hamburger {
    background-color: #badfe7;
}

.menu-expandable {
    background-color: #f6f6f2;
}